// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/HomePage.vue'; // 將 Home 作為首頁組件
import SEOTool from '@/SEOTool.vue'; // 引入新頁面組件
import CozePage from '@/CozePage.vue'; // 引入新頁面組件
import MasterPage from '@/MasterPage.vue'; // 引入新頁面組件
import './../firebaseConfig'; // 引入 Firebase 初始化

const loadCSS = (href) => {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = href;
  document.head.appendChild(link);
};

const removeCSS = (href) => {
  const links = document.querySelectorAll(`link[href="${href}"]`);
  links.forEach(link => link.remove());
};


const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage, // 將 Home 設定為首頁組件
  },
  {
    path: '/seo', // 新頁面的路徑
    name: 'SEOTool',
    component: SEOTool, // 新頁面組件
  },
  {
    path: '/coze', // 新頁面的路徑
    name: 'CozePage',
    component: CozePage, // 新頁面組件
  },
  {
    path: '/aimaster', // 新頁面的路徑
    name: 'MasterPage',
    component: MasterPage, // 新頁面組件
    beforeEnter: () => {
      removeCSS('/css/main.css'); // 動態加載首頁的 CSS
      loadCSS('/css/master.css'); // 動態加載首頁的 CSS
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
